<template>
  <div>
    <div class="header_box">
      <div class="header">
        <div class="left_box" :style="`background:linear-gradient(to right, #00A39B, #00A391) no-repeat;background-size: 100% 100%;`">
          <ul>
            <li>{{nowReadings}}</li>
            <li>当前示数(度)</li>
          </ul>
        </div>
        <div class="right_box">
          <ul>
            <li>
              <font style="float:left">电表名称: </font>
              <div class="edit_class" ref="editclass" contenteditable="true" style="margin-right: 30px;" @blur="changeText">{{meter.name}}</div>
              <!-- <input v-model="meter.name" @change="changeName" ref="textName" :style="`width:${nameLength}rem;margin-right: 30px;`" /> -->
              <img :src="redact" @click="setTextFocus"/>
            </li>
            <li>
              <font>表号:</font>
              <font style="margin-right: 22px">{{meter.assetNo}}</font>
              <span :class="meter.controlState === 'ON' ? 'green_font' : 'red_font'">{{meter.controlState | filterState}}</span>
            </li>
            <li>
              <font>地址:</font>
              <font>{{meter.installAddress}}</font>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer">
        <div>
          <span class="blue_font large_font">{{todayReadings}}</span>
          <span>今日累计用量（度）</span>
        </div>
        <div>
          <span class="large_font">{{beforeYesterdayReadings}}</span>
          <span>昨日累计用量（度）</span>
        </div>
      </div>
    </div>
    <div class="wrap" v-if="showCanvas">
      <div class="title_tip">
        <span>24小时能耗分析</span>
      </div>
      <div class="canvas_wrap">
        <!-- <div class="selectData">
          <span @click="setDataType('year')" :class="{'blue_bg':dataType == 'year'}">年</span>
          <span @click="setDataType('mouth')" :class="{'blue_bg':dataType == 'mouth'}">月</span>
          <span @click="setDataType('week')" :class="{'blue_bg':dataType == 'week'}">周</span>
        </div> -->
        <div>
          <div></div>
          <div class="charts">
            <div id="my_charts" ref="my_charts" :style="myChartsSize"></div>
          </div>
        </div>
        <router-link class="watch_button" tag="a" :to="{ path:'/expenses', query: meter }">查看表计结算明细</router-link>
      </div>
    </div>

    <div v-else style="text-align: center" class="tips_footer">
      <van-empty description="----该表计暂无数据----" />
    </div>

  </div>
</template>

<script>
import address from '@/assets/home/position_3x.png'
import redact from '@/assets/home/write_ico.png'
import detailbdckg from '@/assets/home/biaoji-detail.png'
// 水
import waterIcon from '@/assets/home/water_x.png'
// 电
import powerIcon from '@/assets/home/electricity_x.png'
// 气
import gasIcon from '@/assets/home/airbig_x.png'
import { userDetail } from '@/api/home'
import api from '@/api/index'
const { fetchData } = api
import { mapState, mapActions, mapGetters } from 'vuex'
import noMessage from '@/assets/home/no_message.png'
import echarts from 'echarts'

export default {
  data() {
    return {
      address,
      noMessage,
      detailbdckg,
      gasIcon,
      redact,
      powerIcon,
      waterIcon,
      typeIndex: 0,
      showFlag: false,
      myChartsSize: `width:${document.body.clientWidth}px;height:18rem`,
      dataType: 'year',
      nowReadings: 0,
      yesterdayReadings: 0,
      beforeYesterdayReadings: 0,
      todayReadings: 0,
      meter: [],
      showCanvas: true,
      optionList: [
        { current: 0, list: ['水能', '电能', '气能'] },
        { current: 0, list: ['11', '22', '33'] }
      ],
      option: {
        legend: {
          data: ['今日', '昨日', '月平均']
        },
        toolbox: {
          // show: true,
          // feature: {
          //   mark: { show: true },
          //   dataView: { show: true, readOnly: false },
          //   magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
          //   restore: { show: true },
          //   saveAsImage: { show: true }
          // }
        },
        xAxis: [
          {
            type: 'category',
            data: [1, 2, 3, 4, 5],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            interval: 50,
            axisLabel: {
              margin: 2,
              formatter: function(value, index) {
                if (value >= 10000 && value < 10000000) {
                  value = value / 10000 + '万'
                } else if (value >= 10000000) {
                  value = value / 10000000 + '千万'
                }
                return value
              }
            }
          }
        ],
        series: [
          {
            name: '今日', // 用能量
            type: 'bar',
            smooth: true,
            // areaStyle: {
            //   normal: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            //       offset: 0,
            //       // color: 'rgb(255, 158, 68)'
            //       color: 'rgb(255, 119, 119)'
            //     }, {
            //       offset: 1,
            //       color: 'rgb(255, 255, 255)'
            //     }])
            //   }
            // },
            itemStyle: { normal: { color: '#00A39B' }},
            data: []
          },
          {
            name: '昨日', // 用能量
            type: 'line',
            smooth: true,
            itemStyle: { normal: { color: 'rgb(255, 24, 24)' }},
            data: []
          },
          {
            name: '月平均', // 用能量
            type: 'line',
            smooth: true,
            itemStyle: { normal: { color: 'rgb(255, 190, 62)' }},
            data: []
          }
        ]
      }
    }
  },
  filters: {
    filterState(status) {
      const statusMap = {
        ON: '合闸',
        OFF: '跳闸',
        WAIT_INSTALL: '待安装',
        RUNNING: '运行中',
        SHUT_DOWN: '已关闭'
      }
      return statusMap[status]
    },
    filterIcon(status) {
      const statusMap = {
        GAS: gasIcon,
        POWER: powerIcon,
        WATER: waterIcon
      }
      return statusMap[status]
    },
    filterMeterCostUnit(status) {
      const statusMap = {
        GAS: '立方米',
        POWER: '度',
        WATER: '吨'
      }
      return statusMap[status]
    }
  },
  computed: {
    ...mapState(['user', 'global']),
    meterCostUnit() {
      const statusMap = {
        GAS: '立方米',
        POWER: '度',
        WATER: '吨'
      }
      return statusMap[this.meter.type]
    },
    nameLength() {
      if (!this.meter.name || !this.meter.name.length) {
        return 1
      } else {
        return this.meter.name.length
      }
    }
  },
  created() {},
  mounted() {
    const _self = this
    const data = new Date()
    let yesterday
    let beforeYesterday
    let bgnDate = ''
    this.meter = this.$route.query
    // _self.option.yAxis[0].name = _self.meterCostUnite()
    _self.option.yAxis[0].name = `用量(${this.meterCostUnit})`

    const endDate = `${this.global.currentYear}-${this.global.currentMonth}-${this.global.currentDay}`
    this.myChartsSize = `width:${document.body.offsetWidth * 0.9}px;height:18rem`

    if (data.getDate() > 30 || (data.getMonth() === 1 && data.getDate() >= 28)) {
      bgnDate = `${this.global.currentYear}-${this.global.currentMonth}-01`
    } else {
      bgnDate = `${this.global.currentYear}-${this.global.currentMonth}-01`
    }

    if (data.getDate() === 1) {
      if (data.getMonth() === 2) {
        if (data.getFullYear() % 4 === 0) {
          yesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-29`
          beforeYesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-28`
        } else {
          yesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-28`
          beforeYesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-27`
        }
      } else {
        if (data.getMonth() === 1 || data.getMonth() === 3 || data.getMonth() === 5 || data.getMonth() === 7 || data.getMonth() === 8 || data.getMonth() === 10 || data.getMonth() === 12) {
          yesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-31`
          beforeYesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-30`
        } else {
          yesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-30`
          beforeYesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-29`
        }
      }
    } else if (data.getDate() === 2) {
      if (data.getMonth() === 2) {
        if (data.getFullYear() % 4 === 0) {
          beforeYesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-29`
        } else {
          beforeYesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-28`
        }
      } else {
        if (data.getMonth() === 1 || data.getMonth() === 3 || data.getMonth() === 5 || data.getMonth() === 7 || data.getMonth() === 8 || data.getMonth() === 10 || data.getMonth() === 12) {
          beforeYesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-31`
        } else {
          beforeYesterday = `${this.global.currentYear}-${this.formatDays(data.getMonth())}-30`
        }
      }
      yesterday = `${this.global.currentYear}-${this.global.currentMonth}-${this.formatDays(parseInt(this.global.currentDay) - 1)}`
    } else {
      yesterday = `${this.global.currentYear}-${this.global.currentMonth}-${this.formatDays(parseInt(this.global.currentDay) - 1)}`
      beforeYesterday = `${this.global.currentYear}-${this.global.currentMonth}-${this.formatDays(parseInt(this.global.currentDay) - 2)}`
    }
    fetchData(
      {},
      `/statistics/meter/readings/realtime/meter/${this.meter.id}`,
      'get'
    ).then(res => {
      if (res && res.result) {
        _self.nowReadings = res.result || 0
      } else {
        _self.nowReadings = _self.meter.nowReadings || 0
      }
      return fetchData(
        {},
        `/data/meter/readings/day/${this.meter.id}/${yesterday}`,
        'get'
      )
    }).then(res => {
      if (res) {
        _self.yesterdayReadings = res.readings || 0
      } else {
        _self.yesterdayReadings = 0
      }
      _self.todayReadings = (_self.nowReadings - _self.yesterdayReadings).toFixed(2)

      return fetchData(
        {},
        `/data/meter/readings/day/${this.meter.id}/${beforeYesterday}`,
        'get'
      )
    }).then(res => {
      let beforeYesterdayReadings = 0
      if (res) {
        beforeYesterdayReadings = res.readings || 0
      }
      _self.beforeYesterdayReadings = (_self.yesterdayReadings - beforeYesterdayReadings).toFixed(2)
    }).catch(err => {
      console.log(err)
    })

    let prevDate = yesterday
    if (data.getDate() === 1) {
      prevDate = endDate
    }

    fetchData(
      {},
      `/meter/energy/curve/avg/${this.meter.id}/date/${endDate}/${endDate}`,
      'get'
    ).then(res => {
      _self.setCanvas(res, 0)
    })

    fetchData(
      {},
      `/meter/energy/curve/avg/${this.meter.id}/date/${yesterday}/${yesterday}`,
      'get'
    ).then(res => {
      _self.setCanvas(res, 1)
    })

    fetchData(
      {},
      `/meter/energy/curve/avg/${this.meter.id}/date/${bgnDate}/${prevDate}`,
      'get'
    )
      .then(res => {
        _self.setCanvas(res, 2)
      })
      .catch(err => {
        console.log(err)
        _self.showCanvas = false
      })
  },
  methods: {
    /*
      选择要选择的类型 用能类型 表计位置
    */
    setDataType(type) {
      this.dataType = type
    },
    setCanvas(res, i) {
      const _self = this
      const arr = []
      const arrSeries = []
      for (const key in res) {
        if (key.indexOf('energy') >= 0) {
          arr.push(`${arr.length + 1}`)
          arrSeries.push(res[key])
        }
      }
      _self.option.xAxis[0].data = arr
      _self.option.series[i].data = arrSeries

      const myChart = echarts.init(document.getElementById('my_charts'))
      myChart.setOption(this.option)
      _self.showCanvas = true
    },
    /*
      文本框获取焦点
    */
    setTextFocus() {
      this.$refs.textName.focus()
    },
    meterCostUnite() {
      const statusMap = {
        GAS: '立方米',
        POWER: 'KWh',
        WATER: '吨'
      }
      return statusMap[this.meter.type]
    },
    changeText() {
      if (this.meter.name !== this.$refs.editclass.innerHTML) {
        this.meter.name = this.$refs.editclass.innerHTML
        this.changeName()
      }
    },
    /*
      修改名字
    */
    changeName() {
      const url = `/equipment/meter/${this.meter.id}/name/${this.meter.name}`
      const _self = this
      fetchData({}, url, 'put')
        .then(res => {
          _self.$router.replace({ path: '/home' })
          _self.$router.replace({
            path: '/energyConsumption',
            query: _self.meter
          })
          return res
        })
        .then(res => {
          _self.$toast('修改成功')
        })
        .catch(err => {
          console.error('err', err)
        })
    },
    /*
      获取选择的索引值
    */
    selectTypeIndex(index) {
      this.typeIndex = index
    },
    /*
      是否处于选择状态
     */
    showOption() {
      this.showFlag = !this.showFlag
    },
    /*
      选择了之后的具体信息
     */
    chooseType(index) {
      this.optionList[this.typeIndex].current = index
      this.showOption()
    },
    formatDays(val) {
      if (parseInt(val) < 10) {
        return `0${val}`
      } else {
        return val
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title_tip {
  width: 100%;
  height: 1.3rem;
  line-height: 1.3rem;
  margin: 0.5rem 0;
  float: left;
  span {
    display: inline-block;
    line-height: 1.3rem;
    height: 1.3rem;
    margin-left: 8%;
  }
}
p {
  text-align: center;
}
.tips_footer {
  width: 100%;
  margin-top: 1rem;
  font-size: 0.9rem;
  float: left;
  text-align: center;
  color: #666;
  img {
    width: 40%;
  }
}
.edit_class {
  width:auto;
  float: left;
}
.header_box {
  width: 90%;
  height:auto;
  overflow: hidden;
  margin: 2rem 5%;
  position: relative;
  box-shadow: 0px 0px 20px #cdcdcd;
  padding-top: 0;
  .header {
    width: 90%;
    padding: 1rem 5%;
    min-height: 7rem;
    height: auto;
    overflow: hidden;
    .left_box {
      width: 6.8rem;
      height: 6.8rem;
      border-radius:50%;
      float: left;
      ul {
        width: 100%;
        margin-top: 2rem;
        li {
          width: 100%;
          line-height: 2.0rem;
          text-align: center;
          padding: 0;
          font-size: 0.7rem;
        }
        li:nth-child(1) {
          font-size: 1.5rem;
        }
      }
    }
    .right_box {
      width: calc(100% - 7rem);
      height: auto;
      min-height: 7rem;
      overflow: hidden;
      float: right;
      ul {
        li {
          width: 100%;
          line-height: 2rem;
          margin: 0;
          color: #333;
        }
      }
    }
  }
  .footer {
    width:90%;
    margin: 1rem 5%;
    margin-top: 0;
    padding-top: 1rem;
    height: 4rem;
    border-top:1px solid #E5E5E5;
    .large_font {
      font-size: 1.2rem;
    }
    .blue_font {
      color:#00A49C;
    }
    div {
      width: 50%;
      height: 4rem;
      font-size: 0.9rem;
      float: left;
      span {
        display: inline-block;
        width: 100%;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
      }
    }
  }
  .left {
    float: left;
    width: 26%;
    img {
      height: 2.5rem;
      float: right;
    }
  }
  ul {
    float: right;
    width: 100%;
    li {
      width: 90%;
      padding-left: 5%;
      height: auto;
      min-height: 1.4rem;
      line-height: 1.4rem;
      font-size: 0.9rem;
      color: #fff;
      .red_font{
        display: inline-block;
        color: red;
        font-size: 0.9rem;
        margin-left: 0;
      }
      .green_font{
        display: inline-block;
        color: #00A49C;
        font-size: 0.9rem;
        margin-left: 0;
      }
      input {
        width: 30%;
        color: #333;
        background: none;
        border: none;
        margin: 1px;
        padding: 0 5px;
        font-size: 0.9rem;
      }
      input:hover,
      input:active {
        margin: 0;
        border: 1px solid #dadada;
      }
      .write_name {
        display: inline-block;
        width: auto;
        height: 1.8rem;
        word-wrap: normal;
      }
      img {
        height: 1rem;
      }
      .foot_span {
        display: inline-block;
        width: 49%;
        float: left;
        text-align: center;
        margin-top: 0.5rem;
        border-left: 1px solid #dadada;
        p {
          margin: 0;
          line-height: 0.7rem;
          font-size: 0.9rem;
          color: #555;
        }
        .top {
          line-height: 2rem;
          font-size: 1.2rem;
          color: #e76d6e;
          font-weight: 600;
        }
        .blue_font {
          color: #2e91d2;
        }
      }
    }

    .footer_li {
      position: absolute;
      z-index: 2;
      bottom:-2rem;
      width: 90%;
      height: 4rem;
      background: #fff;
      border-radius: 25px;
      padding-left: 0;
      margin-left: 5%;
      margin-top: 0.8rem;
      border: 1px solid #dadada;
      box-shadow: 3px 5px 5px #dadada;
    }
  }
}

.address {
  margin-right: 0.5rem;
  float: left;
  margin-top: 0.2rem;
}
.wrap {
  width: 100%;
  height: auto
}
.canvas_wrap {
  width: 100%;
  margin: 10px 0;
  .charts {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 1rem;
  }
  #my_charts {
    margin: 0 auto;
    margin-bottom: 0rem;
  }

  .watch_button {
    // position: fixed;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 84%;
    margin: 1rem 8%;
    margin-top: 0;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    background: #00A39B;
    color: #fff;
    border-radius: 80px;
  }
  .selectData {
    width: 216px;
    height: 2rem;
    float: right;
    border: 1px solid #ff6666;
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    overflow: hidden;
    span {
      margin: 0;
      padding: 0;
      float: left;
      display: inline-block;
      text-align: center;
      width: 72px;
      height: 2rem;
      line-height: 2rem;
      background: #eee;
      font-size: 18px;
    }
    .blue_bg {
      background: #ff6666;
      color: #fff;
    }
  }
}
</style>
